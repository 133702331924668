interface LoaderProps {
  dimensions?: "sm" | "md" | "lg";
  color?: string;
}

export const Loader = ({ dimensions = "lg", color = "blue" }: LoaderProps) => {
  const dimensionClasses = {
    sm: "w-4 h-4",
    md: "w-8 h-8",
    lg: "w-12 h-12",
  };

  const colorClasses: Record<string, string> = {
    blue: "bg-blue-500",
    red: "bg-red-500",
    green: "bg-green-500",
    yellow: "bg-yellow-500",
    purple: "bg-purple-500",
    gray: "bg-gray-500",
  };

  return (
    <div className="flex justify-center items-center py-6 md:py-12">
      <div
        className={`
          ${dimensionClasses[dimensions]} 
          ${colorClasses[color] || `bg-${color}-500`}
          rounded-full 
          animate-pulse
        `}
      ></div>
    </div>
  );
};
