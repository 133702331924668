"use client";

import { SignInButton, useAuth } from "@clerk/nextjs";
import { Button } from "@nextui-org/react";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { useEffect } from "react";
import { Loader } from "./components/loader.component";

const Index = () => {
  const router = useRouter();
  const { isSignedIn, isLoaded } = useAuth();

  useEffect(() => {
    if (isLoaded && isSignedIn) {
      router.push("/app");
    }
  }, [isLoaded, isSignedIn]);

  if (!isLoaded) return <Loader dimensions="lg" />;
  if (isSignedIn) return <Loader dimensions="lg" />;

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-white p-4 dark:bg-[#03071C]">
      <div className="w-full max-w-[1280px] space-y-8 text-center">
        {/* Logo Section */}
        <div className="mb-8 flex justify-center">
          <div className="rounded-xl p-3">
            <Image
              src="/assets/logo-dark.svg"
              alt="Logo"
              width={160}
              height={35}
              className="hidden cursor-pointer dark:block"
            />
            <Image
              src="/assets/logo-light.svg"
              alt="Logo"
              width={160}
              height={35}
              className="block cursor-pointer dark:hidden"
            />
          </div>
        </div>

        {/* Welcome Text */}
        <div className="mb-12 space-y-4">
          <h1 className="font-open-sans text-4xl font-medium text-[#171663] dark:text-white md:text-5xl">
            Greetings from <span className="text-[#3E8AFF]">Cleanlist.ai</span>
          </h1>
          <p className="mx-auto max-w-2xl font-open-sans text-lg text-[#171663] opacity-60 dark:text-white">
            Find your potential customers more effectively with state of the art
            cleaning and enrichment.
          </p>
        </div>

        {/* Features Grid */}
        <div className="mx-auto mb-12 grid max-w-4xl gap-6 md:grid-cols-3">
          {[
            {
              icon: "🔍",
              title: "Lead Discovery",
              description: "Find and verify leads automatically",
            },
            {
              icon: "✨",
              title: "Enrichment",
              description: "Enhance and enrich leads with simplicity",
            },
            {
              icon: "🔄",
              title: "CRM",
              description: "Easily connect with CRM platforms",
            },
          ].map((feature, index) => (
            <div
              key={index}
              className="rounded-xl border border-[#1716631A] bg-[#3E8AFF0A] p-6 dark:border-[#FFFFFF1A]"
            >
              <div className="mb-4 text-3xl">{feature.icon}</div>
              <h3 className="mb-2 text-lg font-medium text-[#171663] dark:text-white">
                {feature.title}
              </h3>
              <p className="text-sm text-[#171663] opacity-60 dark:text-white">
                {feature.description}
              </p>
            </div>
          ))}
        </div>

        {/* Login Button */}
        <SignInButton mode="modal">
          <Button
            className="h-12 bg-[#3E8AFF] px-8 text-sm font-medium text-white hover:bg-[#3E8AFF]/90"
            size="lg"
          >
            Get Started
          </Button>
        </SignInButton>
      </div>
    </div>
  );
};

export default Index;
